<template>
  <v-container fluid>
    <h3 class="page-title">System Log</h3>
    <v-row>
      <v-col cols="2">
        <v-menu
          :close-on-content-click="true"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="日期"
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            no-title
            scrollable
            :show-current="false"
            :max="expiredDate"
            locale="zh-tw"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2">
        <v-select
          label="動作類別"
          v-model="action"
          :items="actions"
          outlined
          dense
          hide-details
        >
        </v-select>
      </v-col>
      <v-col cols="2">
        <v-text-field
          label="關鍵字"
          v-model="keyword"
          outlined
          dense
          hide-details
          clearable
        >
        </v-text-field>
      </v-col>
      <v-col cols="auto">
        <v-btn color="primary" @click="download">下載</v-btn>
      </v-col>
      <v-col>
        <v-btn color="primary" class="mr-4" @click="search">查詢</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="result">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="filterResult"
          :mobile-breakpoint="0"
          item-key="index"
          show-expand
          :footer-props="{
            itemsPerPageText: '每頁幾筆',
            itemsPerPageOptions: [10, 30, -1],
          }"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-4">
              <pre>{{ pretty(item.content) }}</pre>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import dayjs from "dayjs";
import downloadCsv from "download-csv";

export default {
  name: "SystemLog",
  data: () => ({
    date: dayjs().format("YYYY-MM-DD"),
    keyword: null,
    開始時間: null,
    結束時間: null,
    result: null,
    action: null,
    actions: [],
    headers: [
      { text: "序號", value: "index" },
      { text: "時間", value: "time" },
      { text: "動作", value: "action" },
      { text: "觸發人", value: "from" },
    ],
  }),
  created() {},
  computed: {
    expiredDate() {
      return dayjs().format("YYYY-MM-DD");
    },
    filterResult() {
      let list = this.result;
      if (this.開始時間) {
        list = list.filter(
          (row) => `${this.date} ${this.開始時間}` <= row.time
        );
      }

      if (this.結束時間) {
        list = list.filter(
          (row) => `${this.date} ${this.結束時間}` >= row.time
        );
      }

      if (this.action) {
        list = list.filter((row) => row.action == this.action);
      }

      if (this.keyword) {
        list = list.filter(
          (row) =>
            row.action.includes(this.keyword) ||
            row.from.includes(this.keyword) ||
            row.content.includes(this.keyword)
        );
      }
      // console.log(list.map((o) => o.from).join('","'));
      return list;
    },
  },
  methods: {
    download() {
      let data = this.filterResult.map((row) =>
        _.pick(row, ["index", "time", "action", "from"])
      );
      downloadCsv(data, {}, `${this.date} 系統 Log 記錄`);
    },
    pretty: (val, indent = 2) => {
      if (typeof val !== "object") {
        try {
          val = JSON.parse(val);
        } catch (err) {
          return val;
        }

        return JSON.stringify(val, null, indent);
      }
    },
    async search() {
      if (!this.date) {
        return false;
      }
      this.$vloading.show();

      let result = (await this.axios.get(`/dashboard/log/${this.date}`)).data;
      result.map((o) => {
        o.content =
          typeof o.content == "object" ? JSON.stringify(o.content) : o.content;
        return o;
      });
      this.result = result.reverse();
      this.actions = Object.keys(_.groupBy(result, "action"));
      this.actions.unshift({ text: "全選", value: "" });

      this.$vloading.hide();
    },
  },
};
</script>

<style lang="scss" scoped></style>
