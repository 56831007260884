<template>
  <v-container>
    <h3 class="page-title">帳號管理</h3>
    <v-row>
      <v-col cols="6" sm="3">
        <v-autocomplete
          v-model="行政區"
          :items="所有行政區"
          label="行政區"
          outlined
          dense
          hide-details
          :disabled="!permissions.canChangeDistrict"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" sm="3">
        <v-text-field
          label="關鍵字"
          v-model="keyword"
          outlined
          dense
          hide-details
          clearable
        ></v-text-field>
      </v-col>

      <v-col class="text-right">
        <v-btn color="primary" @click="create" class="">新增</v-btn>
      </v-col>
    </v-row>
    <div class="mt-3">
      <v-data-table
        :headers="headers"
        :items="filterUsers"
        no-data-text="尚無帳號"
        :mobile-breakpoint="0"
        :footer-props="{
          itemsPerPageText: '每頁幾筆',
          itemsPerPageOptions: [10, 30, -1],
        }"
      >
        <template v-slot:item.動作="{ item }">
          <v-btn small text color="primary" @click="update(item)">修改</v-btn>
        </template>
        <template v-slot:item.enable="{ item }">
          <div
            class="red--text font-weight-medium"
            v-if="item.enable === false"
          >
            停用
          </div>
          <div v-else class="green--text font-weight-medium">啟用</div>
        </template>
      </v-data-table>
    </div>

    <UserDialog
      v-if="dialog"
      v-model="dialog"
      :theUser="selected"
      @load="load"
    ></UserDialog>
  </v-container>
</template>

<script>
import _ from "lodash";
import { 行政區 } from "@/libs/const";
import util from "@/mixins/util";
import dayjs from "dayjs";
import UserDialog from "@/components/Account/userDialog";

export default {
  name: "Account",
  components: {
    UserDialog,
  },
  mixins: [util],
  data: () => ({
    行政區: null,
    keyword: null,
    headers: [
      { text: "行政區", value: "行政區" },
      { text: "帳號", value: "帳號" },
      { text: "角色", value: "角色" },
      { text: "醫事單位", value: "醫事單位" },
      { text: "狀態", value: "enable" },
      { text: "", value: "動作", sortable: false },
    ],
    users: [],
    selected: null,
    dialog: false,
  }),
  computed: {
    filterUsers() {
      let users = this.users;
      if (this.行政區) {
        users = users.filter((row) => this.行政區 == row.行政區);
      }
      if (this.keyword) {
        users = users.filter(
          (row) =>
            row.帳號?.includes(this.keyword) ||
            row.角色?.includes(this.keyword) ||
            row.醫事單位?.includes(this.keyword)
        );
      }
      return users;
    },
    isManager() {
      return ["管理者", "衛生局管理員", "衛生局", "衛生所管理者"].includes(
        this.$store.state.user.角色
      );
    },
    isHospital() {
      return ["醫療院所"].includes(this.$store.state.user.角色);
    },
    所有行政區() {
      let place = _.cloneDeep(行政區);
      place.unshift({
        text: "全部",
        value: null,
      });
      return place;
    },
  },
  async created() {
    if (this.$store.state.user.行政區) {
      this.行政區 = this.$store.state.user.行政區;
    }
    await this.load();
  },
  methods: {
    async load() {
      let users = (await this.axios.get("/dashboard/user")).data;
      this.users = users;
    },
    async update(item) {
      this.selected = item;
      this.dialog = true;
    },
    async create() {
      this.selected = {};
      this.dialog = true;
    },
  },
  watch: {},
};
</script>

<style></style>
