<template>
  <v-dialog v-model="dialog" width="800">
    <v-card class="pb-2">
      <v-card-title class="text-h5 grey lighten-2">
        {{ isNew ? "新增" : "修改" }}帳號
      </v-card-title>
      <v-card-text class="mt-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <div class="text-h6 font-weight-bold">基本資訊</div>
          <v-row>
            <v-col cols="12">
              <v-checkbox
                v-model="user.enable"
                label="啟用帳號"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                label="* 帳號"
                v-model="user.帳號"
                outlined
                dense
                hide-details
                :disabled="!!user._id"
                :rules="[(v) => !!v || '此為必填欄位']"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-text-field
                :label="isNew ? '* 密碼' : '密碼'"
                v-model="password"
                type="password"
                outlined
                dense
                hide-details
                :rules="[(v) => !!v || !isNew || '此為必填欄位']"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3">
              <v-combobox
                label="* 行政區"
                v-model="user.行政區"
                :items="衛生所"
                outlined
                dense
                :disabled="isFixedPlace"
                hide-details
              ></v-combobox>
            </v-col>
            <v-col cols="6" sm="3">
              <v-select
                label="* 角色"
                v-model="user.角色"
                :items="roles"
                outlined
                dense
                hide-details
                :rules="[(v) => !!v || '此為必填欄位']"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="醫事單位"
                v-model="user.醫事單位"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                rows="3"
                label="備註"
                v-model="user.備註"
                outlined
                dense
                hide-details
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-text class="mt-4">
        <div class="text-h6 font-weight-bold">預設場次資訊</div>
        <v-row class="mt-2">
          <v-col cols="6" sm="6">
            <v-text-field
              label="場次地點"
              v-model="user.場次地點"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="3">
            <v-text-field
              label="場次電話"
              v-model="user.場次電話"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="3">
            <v-text-field
              label="醫事機構代碼"
              v-model="user.醫事機構代碼"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="場次地址"
              v-model="user.場次地址"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false"> 關閉 </v-btn>
        <v-btn color="primary" @click="update" v-if="isNew"> 確定新增 </v-btn>
        <v-btn color="primary" @click="update" v-else> 儲存修改 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import { 行政區, userGroup } from "@/libs/const";
export default {
  name: "UserDialog",
  props: ["value", "theUser"],
  data: () => ({
    password: "",
    valid: false,
    user: {},
  }),
  computed: {
    衛生所() {
      let place = 行政區.map((v) => ({
        text: v + "衛生所",
        value: v,
      }));
      place.unshift({
        text: "全部",
        value: null,
      });
      return place;
    },
    roles() {
      let remove = this.$store.state.user.角色;
      if (remove == "管理者") remove = "none";
      return _.difference(userGroup.all, userGroup[remove]);
    },
    dialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    isNew() {
      return !this.user?._id;
    },
    isFixedPlace() {
      return (
        !!this.$store.state.user.行政區 &&
        !["管理者", "衛生局管理員"].includes(this.$store.state.user.角色)
      );
    },
  },
  created() {
    this.user = _.cloneDeep(this.theUser);
    let default行政區 = this.$store.state.user.行政區 || this.user.行政區;
    this.user.行政區 = default行政區
      ? {
          text: default行政區 || +"衛生所",
          value: default行政區,
        }
      : {
          text: "全部",
          value: null,
        };

    if (this.user.enable == undefined) {
      this.user.enable = true;
    }
  },
  methods: {
    async update() {
      this.valid = this.$refs.form.validate();
      if (this.valid) {
        if (this.password) {
          this.user.密碼 = this.password;
        } else {
          delete this.user.密碼;
        }
        if (_.isObject(this.user.行政區)) {
          this.user.行政區 = this.user.行政區.value;
        }
        try {
          await this.axios.post("/dashboard/user", this.user);
          this.dialog = false;
          this.$toast.success(this.user._id ? "修改成功" : "新增成功");
          this.$emit("load");
        } catch (e) {
          this.$toast.error(
            this.user._id
              ? "修改失敗，請聯繫系統管理者！"
              : "新增失敗，請檢查帳號是否重複！"
          );
        }
      }
    },
  },
};
</script>

<style></style>
