<template>
  <v-app>
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-card class="elevation-6" width="400">
            <v-toolbar dark color="primary" class="justify-center">
              <v-toolbar-title>臺南市政府流感疫苗預約平台</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-text-field
                v-model="input.account"
                prepend-icon="mdi-account"
                label="帳號"
                type="text"
                v-on:keyup.enter="login"
              ></v-text-field>
              <v-text-field
                v-model="input.password"
                prepend-icon="mdi-lock"
                label="密碼"
                type="password"
                v-on:keyup.enter="login"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="login">登入</v-btn>
            </v-card-actions>
          </v-card>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import ls from "expired-localstorage";
export default {
  name: "Login",
  data: () => ({
    input: {
      account: "",
      password: "",
    },
    user: {},
  }),
  methods: {
    async login() {
      try {
        this.$vloading.show();
        let data = (await this.axios.post("/dashboard/login", this.input)).data;
        console.log(data);
        this.$vloading.hide();
        if (!data) {
          this.$toast.error("帳號密碼錯誤！");
        } else {
          this.$store.commit("setState", {
            user: data.user,
          });
          ls.set("token", data.token);
          window.location = "/dashboard/checkin";
        }
      } catch (error) {
        alert("帳號密碼錯誤");
        this.$vloading.hide();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
