<template>
  <v-container>
    <h3 class="page-title">匯入名單</h3>
    <v-form ref="form">
      <v-card class="mt-8 pb-4">
        <v-card-title>{{ 年度 }} 年度</v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              v-for="file in fileConfig.listFiles"
              :key="file.key"
            >
              <v-file-input
                :ref="file.key"
                :accept="file.type"
                :label="`${file.label} (${file.type})`"
                outlined
                show-size
                dense
                hide-details
                clearable
                v-model="listFiles[file.key]"
              >
              </v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn large class="px-12 mx-2" color="primary" @click="uploadFile()"
            >上傳</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>

    <v-dialog v-model="dialog" max-width="400px">
      <v-card>
        <v-card-title>匯入結果</v-card-title>
        <v-card-text>
          <div class="text-body-1" v-for="row in result">
            {{ row.type }}: 總共 {{ row.length }} 筆 / 匯入 {{ row.match }} 筆
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">關閉</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { getYear } from "@/libs/const";
import _ from "lodash";
import dayjs from "dayjs";

const 年度 = getYear();

export default {
  name: "ImportList",
  data: () => ({
    年度,
    result: null,
    dialog: false,

    listFiles: {},
    fileConfig: {
      listFiles: [
        {
          label: "NIIS 檔",
          key: "NIIS 檔",
          type: ".xlsx",
        },
        {
          label: "年度接種名冊",
          key: "年度接種名冊",
          type: ".xlsx",
        },
      ],
    },
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      _.each(this.fileConfig.listFiles, (config) => {
        this.listFiles[config.key] = null;
      });
    },
    async uploadFile() {
      let formData = new FormData();
      _.each(this.listFiles, (file, key) => {
        formData.append(key, file);
      });

      this.$vloading.show();
      setTimeout(async () => {
        try {
          this.result = (
            await this.axios.post("/dashboard/file", formData)
          ).data;
          this.dialog = true;
          this.$refs.form.reset();
        } catch (e) {
          alert(e);
        }

        this.$vloading.hide();
        this.init();
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped></style>
