import Vue from "vue";
import VueGtag from "vue-gtag";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Toast from "vue-toastification";
import ls from "expired-localstorage";
import { LoadingPlugin } from "vuetify-loading-overlay";
import axios from "axios";
import VueQrcodeReader from "vue-qrcode-reader";
import dInput from "@/components/Common/dInput";

import { apiURL, notHttpsApiURL } from "../env.js";
import "vue-toastification/dist/index.css";

let URL = apiURL;

if (location.protocol !== "https:") {
  URL = notHttpsApiURL;
}
//
axios.interceptors.request.use(
  function (config) {
    let token = ls.get("token");
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    config.url = config.alone ? config.url : URL + config.url;
    return config;
  },
  function (error) {
    console.log(error.message);
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          location.href = location.pathname.includes("/dashboard")
            ? "/dashboard"
            : "/";
          break;
        default:
          console.log(error.message);
      }
    }
    return Promise.reject(error);
  }
);

Vue.use(
  VueGtag,
  {
    config: { id: "G-4BEL4ZMT42" },
    pageTrackerScreenviewEnabled: true,
  },
  router
);
Vue.use(Toast, {
  position: "bottom-center",
  timeout: 10000,
  pauseOnHover: false,
  hideProgressBar: false,
  bodyClassName: ["toast-text"],
});
Vue.use(LoadingPlugin, {
  spinnerProps: {
    color: "#0F4C81",
    width: "7",
    size: 70,
  },
  overlayProps: {
    "z-index": 9999,
  },
});
Vue.use(VueQrcodeReader);

Vue.component("d-input", dInput);

Vue.config.productionTip = false;
Vue.prototype.axios = axios;
Vue.prototype.ls = ls;
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
