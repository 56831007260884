<template>
  <router-view />
</template>

<script>
export default {
  name: "App",

  data: () => ({}),
};
</script>
<style lang="scss">
html {
  overflow-y: auto !important;
}
.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.pointer {
  cursor: pointer;
}
</style>
