<template>
  <v-container>
    <h3 class="page-title">場次設定</h3>
    <v-row>
      <v-col cols="2" sm="2">
        <v-autocomplete
          v-model="selected.行政區"
          outlined
          :items="所有行政區"
          label="行政區"
          dense
          hide-details
          :disabled="!permissions.canChangeDistrict"
        ></v-autocomplete>
      </v-col>
      <v-col cols="2" sm="2">
        <v-autocomplete
          v-model.number="selected.年度"
          :items="年度"
          label="年度"
          outlined
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col cols="2" sm="2">
        <v-text-field
          v-model="keyword"
          label="關鍵字"
          outlined
          dense
          hide-details
          clearable
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" class="ml-auto text-right">
        <template>
          <v-btn class="mr-4" color="warning" text @click="downloadSample()">
            批次範例
          </v-btn>
          <v-btn class="mr-4" color="warning" @click="$refs.uploader.click()">
            批次匯入
          </v-btn>
        </template>

        <input ref="uploader" class="d-none" type="file" @change="upload" />
        <v-btn color="primary" @click="create">新增</v-btn>
      </v-col>
    </v-row>
    <div class="mt-4">
      <v-data-table
        :headers="headers"
        :items="filterSections"
        no-data-text="尚無場次"
        :mobile-breakpoint="0"
        :footer-props="{
          itemsPerPageText: '每頁幾筆',
          itemsPerPageOptions: [10, 30, -1],
        }"
      >
        <template v-slot:item.開始時間="{ item }">
          {{ item.開始時間 }}~{{ item.結束時間 }}
        </template>
        <template v-slot:item.enable="{ item }">
          {{ item.enable ? "開放" : "暫停" }}
        </template>
        <template v-slot:item.動作="{ item }">
          <v-btn
            small
            text
            color="primary"
            @click="update(item)"
            :disabled="item.人數上限 <= item.已配發"
            >修改</v-btn
          >
          <v-btn
            small
            text
            color="error"
            @click="remove(item)"
            :disabled="item.人數上限 <= item.已配發"
            >刪除</v-btn
          >
        </template>
      </v-data-table>
    </div>
    <sectionDialog
      v-if="dialog"
      v-model="dialog"
      :theSection="selected.section"
      @load="load"
    ></sectionDialog>
    <v-dialog v-if="previewDialog" v-model="previewDialog">
      <v-card>
        <v-card-title>批次匯入預覽</v-card-title>
        <v-card-text class="pt-2">
          <div v-for="(row, n) in importRows" :key="n">
            <v-row align="center" class="mt-0">
              <v-col cols="1">
                <v-checkbox
                  class="mt-0 pt-0"
                  v-model="row.enable"
                  label="開放"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="row.日期"
                  outlined
                  label="日期"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-text-field
                  v-model="row.行政區"
                  outlined
                  label="行政區"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="1">
                <v-text-field
                  v-model="row.開始時間"
                  outlined
                  label="開始時間"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-text-field
                  v-model="row.結束時間"
                  outlined
                  label="結束時間"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-text-field
                  v-model="row.人數上限"
                  outlined
                  label="人數上限"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="row.接種疫苗"
                  outlined
                  label="接種疫苗"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row align="center" class="mt-0">
              <v-col cols="2" offset="1">
                <v-text-field
                  v-model="row.地點"
                  outlined
                  label="地點"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-model="row.醫事機構代碼"
                  outlined
                  label="醫事機構代碼"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col cols="2">
                <v-text-field
                  v-model="row.電話"
                  outlined
                  label="電話"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="row.地址"
                  outlined
                  label="地址"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-divider class="my-4"></v-divider>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="previewDialog = false"> 關閉 </v-btn>
          <v-btn color="primary" @click="importList"> 確定匯入 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { 行政區, getYear } from "@/libs/const";
import util from "@/mixins/util";
import dayjs from "dayjs";
import downloadCsv from "download-csv";
import sectionDialog from "@/components/Section/sectionDialog";
import * as XLSX from "xlsx/xlsx.mjs";

const all行政區 = {
  text: "全部",
  value: null,
};

const 年度 = getYear();
export default {
  name: "Section",
  components: { sectionDialog },
  mixins: [util],
  data: () => ({
    dialog: false,
    keyword: null,
    previewDialog: false,
    importRows: [],
    sections: [],
    headers: [
      { text: "年度", value: "年度" },
      { text: "行政區", value: "行政區" },
      { text: "地點", value: "地點" },
      { text: "日期", value: "日期" },
      { text: "時間", value: "開始時間" },
      { text: "接種疫苗", value: "接種疫苗" },
      { text: "人數上限", value: "人數上限", sortable: false },
      { text: "已預約", value: "已預約", sortable: false },
      { text: "狀態", value: "enable", sortable: false },
      { text: "", value: "動作", sortable: false },
    ],
    selected: {
      section: {},
      行政區: all行政區,
      年度,
    },
  }),
  computed: {
    filterSections() {
      let sections = this.sections;
      if (this.keyword) {
        sections = sections.filter((r) => r.地點.includes(this.keyword));
      }
      return sections;
    },
    所有行政區() {
      let place = _.cloneDeep(行政區);
      place.unshift({
        text: "全部",
        value: null,
      });
      return place;
    },
    年度() {
      let years = [];
      for (let n = -1; n <= 2; n++) {
        years.push(年度 - n);
      }
      return years;
    },
  },
  async created() {
    if (this.$store.state.user.行政區) {
      this.selected.行政區 = this.$store.state.user.行政區;
    }
    await this.load();
  },
  methods: {
    async upload(e) {
      let file = e.target.files[0];
      let data = await file.arrayBuffer();
      let workbook = XLSX.read(data);
      let worksheet = workbook.Sheets[workbook.SheetNames[0]];
      let importSection = XLSX.utils.sheet_to_json(worksheet, {
        raw: false,
      });

      importSection = importSection.map((o) => {
        o.日期 = dayjs(o.日期).format("YYYY-MM-DD");
        o.開始時間 = dayjs(`${o.日期} ${o.開始時間}`).format("HH:mm");
        o.結束時間 = dayjs(`${o.日期} ${o.結束時間}`).format("HH:mm");
        o.年度 = dayjs(o.日期).year() - 1911;
        o.人數上限 = parseInt(o.人數上限);
        o.enable = o.開放預約 == "1";

        return _.pick(o, [
          "enable",
          "行政區",
          "日期",
          "開始時間",
          "結束時間",
          "人數上限",
          "地點",
          "接種疫苗",
          "醫事機構代碼",
          "地點",
          "電話",
          "地址",
        ]);
      });

      this.importRows = importSection;
      this.previewDialog = true;
    },

    async create() {
      this.接種疫苗 = [];
      this.selected.section = {
        人數上限: 0,
      };

      this.dialog = true;
    },
    async remove(section) {
      if (confirm("確定刪除此場次？")) {
        try {
          await this.axios.post(`/dashboard/section/remove/${section._id}`);
          this.dialog = false;
          this.$toast.success("刪除成功");
          this.load();
        } catch (e) {
          this.$toast.error("刪除失敗");
        }
      }
    },
    async update(section) {
      this.selected.section = section;
      this.dialog = true;
    },
    async load() {
      if (
        this.selected.行政區 instanceof Object &&
        !this.selected.行政區.value
      ) {
        this.selected.行政區 = null;
      }
      let params = {
        年度: this.selected.年度,
        行政區: this.selected.行政區?.value || this.selected.行政區,
      };
      let sections = (
        await this.axios.get("/dashboard/section", {
          params,
        })
      ).data;
      this.sections = _.orderBy(sections, ["日期", "時間"], ["desc", "asc"]);
    },
    async importList() {
      try {
        await this.axios.post("/dashboard/section", this.importRows);
        this.previewDialog = false;
        this.$toast.success("匯入完成");
        await this.load();
      } catch (error) {
        this.$toast.error(error.response.data);
      }
    },
    downloadSample() {
      window.open(
        `https://docs.google.com/spreadsheets/d/1Od3F7mCalMstH2L0oSZqFoUNMm7YSwrEDgkdb4H90LI/edit?gid=0#gid=0`,
        "_blank"
      );
    },
  },
  watch: {
    "selected.行政區"() {
      this.load();
    },
    "selected.年度"() {
      this.load();
    },
  },
};
</script>

<style lang="scss" scoped></style>
