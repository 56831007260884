<template>
  <v-app>
    <v-app-bar-nav-icon
      @click.stop="drawerState = !drawerState"
    ></v-app-bar-nav-icon>
    <v-navigation-drawer
      v-model="drawerState"
      absolute
      color="#0F4C81"
      dark
      hide-overlay
      mobile-breakpoint="30000"
    >
      <div class="d-flex justify-center mt-2">
        <v-img
          @click="$router.push('/dashboard')"
          class="pointer"
          width="100px"
          height="100px"
          contain
          :src="`../images/logo.png`"
        ></v-img>
      </div>
      <div class="white--text text-center text-h6 mb-2">臺南疫苗系統</div>
      <v-list nav dense>
        <v-list-item-group v-model="selectedItem">
          <v-list-item
            v-for="item of aclMenu"
            :key="item.key"
            @click="doAction(item.key)"
          >
            <v-list-item-title class="text-body-1 py-2 my-2">{{
              item.title
            }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
const defaultDrawer = false;
import ls from "expired-localstorage";
import { userGroup } from "@/libs/const";

export default {
  name: "DashboardTemplate",

  data: () => ({
    selectedItem: 0,
    menu: [
      {
        title: "報到作業",
        key: "checkin",
        acl: [],
      },
      {
        title: "場次設定",
        key: "section",
        acl: [],
      },
      {
        title: "匯入名單",
        key: "importList",
        acl: ["管理者"],
      },
      {
        title: "帳號管理",
        key: "account",
        acl: ["管理者", "衛生局管理者", "衛生所管理者"],
      },
      {
        title: "System Log",
        key: "systemLog",
        acl: ["管理者"],
      },
      {
        title: "登出",
        key: "logout",
        acl: [],
      },
    ],
  }),
  created() {
    this.$store.commit("toggleDrawerState", defaultDrawer);
    let key = this.$route.name;
    this.selectedItem = _.findIndex(this.aclMenu, { key });
  },
  computed: {
    drawerState: {
      get() {
        return this.$store.getters.drawerState;
      },
      set(v) {
        return this.$store.commit("toggleDrawerState", v);
      },
    },
    aclMenu() {
      let menu = [...this.menu];
      menu = menu.filter((item) => {
        if (!item.acl.length) return true;
        return item.acl.includes(this.$store.state.user.角色);
      });
      return menu;
    },
  },
  methods: {
    doAction(key) {
      if (key == "logout") {
        ls.remove("token");
        this.$router.push(`/dashboard`);
      } else {
        this.$router.push(`/dashboard/${key}`);
      }
    },
  },
};
</script>
<style lang="scss">
.page-title {
  font-size: 18px;
  margin-bottom: 20px;
}
</style>
