<template>
  <v-dialog v-model="dialog" width="400" persistent>
    <v-card class="pb-2">
      <v-card-title class="text-h5 grey lighten-2"> 現場掛號 </v-card-title>
      <v-card-text class="mt-8">
        <v-row col="12">
          <v-text-field
            label="身分證字號"
            v-model="身分證字號"
            dense
            outlined
            hide-details
          ></v-text-field>
        </v-row>
        <v-row class="mt-8" col="12">
          <v-select
            v-model="vaccines"
            :items="vaccineOptions"
            label="接種疫苗"
            multiple
            dense
            outlined
            hide-details
          ></v-select>
        </v-row>
        <v-row>
          <v-checkbox
            v-model="skipAge"
            label="特殊族群"
            hide-details
          ></v-checkbox>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false"> 取消 </v-btn>
        <v-btn color="success" @click="walkin"> 掛號 </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import { vaccineTypes } from "@/libs/const";
export default {
  name: "WalkinDialog",
  props: ["value", "id", "section"],
  data: () => ({
    身分證字號: "",
    vaccines: [],
    skipAge: false,
  }),
  computed: {
    vaccineOptions() {
      return vaccineTypes.map((o) => ({
        text: `${o.key} - ${o.label}`,
        value: o.key,
      }));
    },
    dialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  created() {
    this.身分證字號 = this.id;
  },
  methods: {
    async walkin() {
      try {
        await this.axios.post(`/dashboard/section/walkin/${this.section._id}`, {
          身分證字號: this.身分證字號,
          vaccines: this.vaccines,
          skipAge: this.skipAge,
        });
        this.$toast.success(`${this.身分證字號} 現場掛號成功`);
        this.dialog = false;
        this.$emit("loadAndPrint", this.身分證字號);
      } catch (error) {
        this.$toast.error(error.response.data.replace(/,/g, "\n"));
      }
    },
  },
};
</script>

<style></style>
