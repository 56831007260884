<template>
  <v-text-field
    :label="label"
    :value="value"
    @input="change"
    outlined
    type="number"
    :rules="[integer]"
    :dense="option.dense == undefined ? false : option.dense"
    :disabled="option.disabled == undefined ? false : option.disabled"
    :clearable="option.clearable == undefined ? true : option.clearable"
    :suffix="option.suffix || ''"
  ></v-text-field>
</template>

<script>
export default {
  props: ["label", "value", "max", "option"],
  computed: {
    integer() {
      return this.option.integer
        ? (v) => /^[0-9]+$/.test(v) || !v || "僅允許整數值"
        : true;
    },
  },
  methods: {
    change(value) {
      value = parseFloat(value);
      if (this.max != undefined && value > parseFloat(this.max)) {
        value = value / 10;
      }

      this.$emit("input", value);
    },
  },
};
</script>

<style>
</style>