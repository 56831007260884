<script>
import _ from "lodash";
import dayjs from "dayjs";
import { userGroup } from "@/libs/const";
export default {
  computed: {
    _() {
      return _;
    },
    age() {
      let birth = this.person.出生年月日 + 19110000;
      return dayjs().diff(birth.toString(), "year");
    },

    isHospital() {
      return ["醫療院所"].includes(this.$store.state.user.角色);
    },
    isManager() {
      return ["管理者", "衛生局管理員", "衛生局", "衛生所管理者"].includes(
        this.$store.state.user.角色
      );
    },
    isAdmin() {
      return ["管理者", "衛生局管理員"].includes(this.$store.state.user.角色);
    },
    permissions() {
      return {
        canChangeDistrict: userGroup.衛生局.includes(
          this.$store.state.user.角色
        ),
      };
    },
  },
  methods: {
    性別(person) {
      switch (person.身分證字號[1]) {
        case "1":
        case "A":
        case "C":
          return "男";
          break;
        case "2":
        case "B":
        case "D":
          return "女";
          break;
      }
    },
  },
};
</script>

<style></style>
