import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/dashboard",
    component: require("@/views/Dashboard/Login.vue").default,
  },
  {
    path: "/dashboard",
    component: require("@/views/Dashboard/Template.vue").default,
    children: [
      {
        path: "checkin",
        name: "checkin",
        component: require("@/views/Dashboard/Checkin.vue").default,
      },
      {
        path: "account",
        name: "account",
        component: require("@/views/Dashboard/Account.vue").default,
      },
      {
        path: "section",
        name: "section",
        component: require("@/views/Dashboard/Section.vue").default,
      },
      {
        path: "importList",
        name: "importList",
        component: require("@/views/Dashboard/ImportList.vue").default,
      },
      {
        path: "systemLog",
        name: "systemLog",
        component: require("@/views/Dashboard/SystemLog.vue").default,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
