module.exports.行政區 = [
  "中西區",
  "東區",
  "南區",
  "北區",
  "安平區",
  "安南區",
  "永康區",
  "歸仁區",
  "新化區",
  "左鎮區",
  "玉井區",
  "楠西區",
  "南化區",
  "仁德區",
  "關廟區",
  "龍崎區",
  "官田區",
  "麻豆區",
  "佳里區",
  "西港區",
  "七股區",
  "將軍區",
  "學甲區",
  "北門區",
  "新營區",
  "後壁區",
  "白河區",
  "東山區",
  "六甲區",
  "下營區",
  "柳營區",
  "鹽水區",
  "善化區",
  "大內區",
  "山上區",
  "新市區",
  "安定區",
];

// module.exports.vaccineType = {
//   賽諾菲: "Flu",
//   東洋: "Flu",
//   國光: "Flu",
//   高端: "Flu",
//   GSK: "Flu",
// };

module.exports.vaccineTypes = [
  // {
  //   key: "賽諾菲",
  //   type: "流感疫苗",
  //   label: "流感",
  //   code: "Flu",
  // },
  // {
  //   key: "東洋",
  //   type: "流感疫苗",
  //   label: "流感",
  //   code: "Flu",
  // },
  // {
  //   key: "國光",
  //   type: "流感疫苗",
  //   label: "流感",
  //   code: "Flu",
  // },
  // {
  //   key: "高端",
  //   type: "流感疫苗",
  //   label: "流感",
  //   code: "Flu",
  // },
  {
    key: "JN",
    type: "新冠疫苗",
    label: "莫德納JN.1",
    code: "CoV_Moderna_JN",
  },
  {
    key: "JNK",
    type: "新冠疫苗",
    label: "兒童莫德納JN.1",
    code: "CoV_Moderna_JN",
  },
  {
    key: "Flu",
    type: "流感疫苗",
    label: "流感",
  },
  {
    key: "13PCV",
    type: "肺炎鏈球菌疫苗",
    label: "第一劑13PCV肺鏈",
  },
  {
    key: "PPV",
    type: "肺炎鏈球菌疫苗",
    label: "第二劑23PPV肺鏈",
  },
  {
    key: "MPV",
    type: "M痘疫苗",
    label: "M痘",
  },
];

module.exports.userGroup = {
  管理者: ["管理者", "衛生局管理者", "衛生所管理者"],
  衛生局管理者: ["管理者", "衛生局管理者"],
  衛生局: ["管理者", "衛生局管理者", "衛生局"],
  衛生所管理者: ["管理者", "衛生局管理者", "衛生局", "衛生所管理者"],
  衛生所: ["管理者", "衛生局管理者", "衛生局", "衛生所管理者", "衛生所"],
  醫事單位: [
    "管理者",
    "衛生局管理者",
    "衛生局",
    "衛生所管理者",
    "衛生所",
    "醫事單位",
  ],
  all: [
    "管理者",
    "衛生局管理者",
    "衛生局",
    "衛生所管理者",
    "衛生所",
    "醫事單位",
  ],
  none: [],
};

module.exports.getYear = () => {
  // 年度以六月分界
  // let month = new Date().getMonth() + 1;
  let year = new Date().getFullYear() - 1911;
  // if (month < 6) year--;
  return year;
};
