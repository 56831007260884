import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";
import { getField, updateField } from "vuex-map-fields";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    drawerState: true,
    token: null,
    user: {},
    person: null,
    input: {},
  },
  mutations: {
    updateField,
    setState(state, object) {
      _.forEach(object, function (value, key) {
        state[key] = value;
      });
    },
    toggleDrawerState(state, data) {
      state.drawerState = data;
    },
  },
  getters: {
    getField,
    drawerState: (state) => state.drawerState,
  },
  actions: {},
  modules: {},
});
